import React from 'react'

function Fourth({setModalOpen}) {
  return (
    <section className="fourth">
        <div className="container text-center">
            <img src="/1_(3).jpg" alt="fourth img"/>
            <button onClick={()=>setModalOpen(true)} type="button" className="open-modal">
                Spill nå Crazy Monkey Slot
            </button>
        </div>
    </section>
  )
}

export default Fourth