import React from 'react'

function Second() {
  return (
    <section className="second">
        <div className="container">
            <div className="inner-wrapper d-flex align-items-center justify-content-between mb-5">
                <div className="left-col">
                    <p className="text">
                        Dette sosiale kasinoet er laget for både nybegynnere og erfarne spillere, og tilbyr mange bonuser og muligheten til å avsløre hemmeligheter og skjulte bonuser. Hvert nytt nivå er en sjanse til å perfeksjonere dine ferdigheter og nyte fantastiske visuelle effekter og fengende lyd. Crazy Monkey Slot er mer enn bare et spill, det er en hel opplevelse som involverer deg fra første spinn.
                    </p>
                </div>
                <div className="right-col">
                    <img src="/2_(2).jpg" alt="second img"/>
                </div>
            </div>
            <p className="text text-md">
                Attraktivt design og festlig atmosfære i spillet skaper en virtuell fest der hver spiller kan nyte saftig gameplay. Sosialt kasino Crazy Monkey Slot gir deg muligheten til å nyte sødmen av seier i et gratis spill, mens du dykker inn i et rike av spenning og moro. I dette spillet finner du et fyrtårn av trøst og nytelse, og avslører hemmeligheter og skjulte bonuser i hver nye sesjon.
            </p>
        </div>
    </section>
  )
}

export default Second