import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
const Footer=()=> {
  const [cookieIsAccepted,setCookieIsAccepted]=useState(true)
  const [ageIsAccepted,setAgeIsAccepted]=useState(true)

  useEffect(()=>{
const value=localStorage.getItem("cookie")
const ageValue=localStorage.getItem("age")
if(value)
{
  setCookieIsAccepted(false)
}
if(ageValue)
  {
    setAgeIsAccepted(false)
  }
  },[])

const acceptCookie=()=>{
  setCookieIsAccepted(false)
  localStorage.setItem("cookie",true)
}
const acceptAge=()=>{
  setAgeIsAccepted(false)
  localStorage.setItem("age",true)
}

  return (
    <>
        <footer>
    <div className="top-row">
        <div className="container">
            <p className="footer-text text">
                Dette nettstedet er dedikert til å tilby gratis underholdning. Alle spillene som tilbys på nettstedet vårt er utviklet internt og er utelukkende laget for underholdningsformål. De er beregnet på voksne over 18 år. Våre sosiale kasinospill involverer ikke innsatser med ekte penger og tilbyr ikke pengepremier. Suksess i disse spillene bør ikke tolkes som en indikasjon på suksess i pengespill med ekte penger. Vi fremmer ansvarlig spillpraksis og understreker at spillene våre kun er ment som underholdning.
            </p>
        </div>
    </div>
    <div className="bottom-row mt-3 mb-3">
        <div className="container d-flex align-items-center justify-content-between">
            <div className="logo-wrapper d-flex align-items-center justify-content-between">
                <a href="/">
                    <img src="/logo.png" alt="logo"/>
                </a>
                <a href="/" className="main-logo-text">
                    <p className="game-name">
                        Crazy Monkey Slot
                    </p>
                    <span>
                        Ditt Gratis Sosiale Kasino
                    </span>
                </a>
            </div>
            <div className="navigation">
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" focusable="false"><rect x="4" y="7.5" width="16" height="1.5"></rect><rect x="4" y="15" width="16" height="1.5"></rect></svg>
                <ul className="nav-list d-flex align-items-center justify-content-center">
                    <li>
                        <a href="/">
                            Hjem
                        </a>
                    </li>
                    <li>
                        <a href="contact-us">
                            Kontakt oss
                        </a>
                    </li>
                    <li>
                        <a href="privacy-policy">
                            Retningslinjer for personvern
                        </a>
                    </li>
                    <li>
                        <a href="terms-and-conditions">
                            Vilkår og betingelser
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>
{
    cookieIsAccepted &&(
      <div className="cookie-banner" id="cookie-banner" style={{display:'flex'}}>
      <p>We use cookies to improve the performance of our website. By continuing to use the site, you agree to the use of cookies.</p>
      <button  onClick={()=>acceptCookie()}  id="accept-cookies">Submit</button>
  </div>
    )
  }
  {
    ageIsAccepted &&(
      <div className="age-check-popup" id="age-check-popup">
        <div className="popup-inner-wrapper">
            <h3 className="text-center">
                Aldersbekreftelse
            </h3>
            <p className="popup-text text-center">
                Du må være <span>18</span> år gammel for å delta.
            </p>
            <button type="button" onClick={()=>acceptAge()} className="text-center popup-btn">
                Ja
            </button>
        </div>
        
    </div>
    )
  }
    </>

  );
}

export default Footer;