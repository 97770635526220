import React from 'react'

function Hero() {
  return (
    <section className="hero">
        <div className="container">
            <h1 className="text-center">
                Sosialt kasino Crazy Monkey Slot er en unik mulighet til å nyte alle følelsene som et spilleautomatspill gir
            </h1>
            <div className="inner-wrapper d-flex align-items-center justify-content-between mb-5">
                <div className="left-col">
                    <img src="/1_(2).jpg" alt="hero img"/>
                </div>
                <div className="right-col">
                    <p className="hero-text text">
                        Denne gratis underholdningen lar deg dykke inn i en fortryllende verden av pengespill og føle de samme følelsene som i et ekte kasino, uten å forlate hjemmet. Utviklet for bruk på mobile enheter og datamaskiner, er spillet tilgjengelig på iOS og Android, og gir brukerne muligheten til å nyte det hvor som helst og når som helst.
                    </p>
                </div>
            </div>
            <p className="hero-text text text-md">
                Fra første stund vil du bli fanget av spillets festlige atmosfære. Lyse farger og lokkende lyder skaper en overveldende visuell og auditiv nytelse. Spillens symboler, som gullmynter, lysende lanterner og saftige frukter, trekker deg inn i en virtuell fest der hver spinn er en sjanse til å vinne. Crazy Monkey Slot gir virkelig en uforglemmelig opplevelse og gjør hver spiller til en del av vårt sosiale fellesskap.
            </p>
        </div>
    </section>
  )
}

export default Hero