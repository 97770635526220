import React from 'react'

function Third() {
  return (
    <section className="third">
        <div className="container">
            <div className="inner-wrapper d-flex align-items-center justify-content-between mb-5">
                <div className="left-col">
                    <img src="/logo.png" alt="second img"/>
                </div>
                <div className="right-col">
                    <p className="text">
                        For de som leter etter visuell nytelse, tilbyr Crazy Monkey Slot fengslende spillesymboler som gjør hver spinn virkelig spennende. Spillet har lyse farger som bringer hvert element av grensesnittet til liv, og skaper en virkelig festlig atmosfære. Dette gratis sosiale kasinoet lar deg føle følelsene til et ekte kasino uten å risikere dine egne penger.
                    </p>
                </div>
            </div>
            <p className="text text-md">
                Crazy Monkey Slot er en hel opplevelse som vil etterlate uforglemmelige inntrykk. Dykk ned i den fortryllende verdenen til sosialt kasino og nyt hvert minutt av spillet. Med støtte for mobile enheter og datamaskiner er spillet alltid innen rekkevidde, slik at du kan nyte det når som helst. Crazy Monkey Slot er en gratis underholdning som gir glede og nytelse uten å forlate hjemmet.
            </p>
        </div>
    </section>
  )
}

export default Third