import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navigation=()=> {
  const [menuIsOpen,setIsMenu]=useState(false)
  
  return (
    <header>
        <div className="container d-flex align-items-center justify-content-between">
            <div className="logo-wrapper d-flex align-items-center justify-content-between">
                <a href="/">
                    <img src="/logo.png" alt="logo"/>
                </a>
                <a href="/" className="main-logo-text">
                    <p className="game-name">
                        Crazy Monkey Slot
                    </p>
                    <span>
                        Ditt Gratis Sosiale Kasino
                    </span>
                </a>
            </div>
            <div className="navigation">
                <svg onClick={()=>setIsMenu(true)} width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" focusable="false"><rect x="4" y="7.5" width="16" height="1.5"></rect><rect x="4" y="15" width="16" height="1.5"></rect></svg>
                <img onClick={()=>setIsMenu(false)} src="/icon-close.svg" alt="close icon" className={"close-nav " + (menuIsOpen === true ? "open" : "")}/>
                <ul className={"nav-list d-flex align-items-center justify-content-center " + (menuIsOpen === true ? "open" : "")}>               
                    <li>
                        <a href="/">
                            Hjem
                        </a>
                    </li>
                    <li>
                        <a href="contact-us">
                            Kontakt oss
                        </a>
                    </li>
                    <li>
                        <a href="privacy-policy">
                            Retningslinjer for personvern
                        </a>
                    </li>
                    <li>
                        <a href="terms-and-conditions">
                            Vilkår og betingelser
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </header>
  );
}

export default Navigation;